<!--
 * @Description: 上传合同
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-28 15:26:19
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/components/contract.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">上传合同</div>
      <div class="model-content">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="medium"
          label-width="100px"
        >
          <el-col :span="24">
            <el-form-item label="签署日期" prop="contract_sign_at">
              <el-date-picker
                style="width: 272px"
                v-model="form.contract_sign_at"
                type="date"
                placeholder="选择签署日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="合同" required prop="">
              <div class="upload-view" @click="handleUpload">
                <div class="upload-icon">
                  <img src="../../../../../assets/icon/add1.png" alt="" />
                </div>
                <div class="upload-text">点击上传合同</div>
              </div>
              <div class="model-file" style="width: 272px">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="model-btn">
        <div class="btn confirm" @click="submitForm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { getToken, uploadImage } from "../../../../../utils/common";
import httpConfig from "../../../../../utils/index";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
const URL = {
  uploadContract: "api/operation/order/uploadContract",
};
export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "add",
    },
    data: {
      type: Object,
    },
  },

  data() {
    return {
      // 表单参数
      form: {
        contract_sign_at: moment().format("YYYY-MM-DD"),
      },
      // 表单校验
      rules: {
        contract_sign_at: [
          { required: true, message: "请选择合同签署日期", trigger: "change" },
        ],
      },
      fileList: [],
      files: null,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {},
  methods: {
    handleRemove() {
      this.files = null;
    },
    // 选择文件
    async handleUpload() {
      const files = await uploadImage(["pdf"]);
      this.fileList = [
        {
          name: files.name,
        },
      ];
      this.files = files;
    },
    /** 提交按钮 */
    async submitForm() {
      try {
        await this.$refs["form"].validate();
        if (!this.files) {
          this.$message.error("请上传合同");
          return;
        }

        const formData = new FormData();
        formData.append("file", this.files);
        formData.append("orderno", this.data.orderno);
        formData.append("contract_sign_at", this.form.contract_sign_at);
        const url = `${httpConfig.URL_PREFIX_API[1]}${URL.uploadContract}`;
        const resp = await fetch(url, {
          method: "post",
          headers: {
            token: getToken(),
          },
          body: formData,
        });
        const res = await resp.json();
        if (res.code === 200) {
          this.shows = false;
          this.$message.success("上传成功");
          this.$emit("update");
        } else {
          this.$message.error(res.message);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 510px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-sub-text {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #b4b4b4;
      line-height: 24px;
      text-align: center;
      margin-top: 14px;
    }
    &-content {
      width: 420px;
      overflow: hidden;
      margin: auto {
        top: 40px;
      }
      .upload-view {
        width: 140px;
        height: 100px;
        background: #fff;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid #dcdfe6;
        cursor: pointer;
        .upload-icon {
          width: 27px;
          height: 27px;
          overflow: hidden;
          margin: auto {
            top: 25px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .upload-text {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
          font-weight: normal;
          color: #b4b4b4;
          line-height: 14px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 18px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep {
  .el-upload {
    display: none;
  }
}
</style>
