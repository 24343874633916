<!--
 * @Description: 套餐详情
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:39:02
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/mods/package.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="时间:" prop="createTime">
        <el-date-picker
          align="center"
          style="width: 240px"
          v-model="queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司名称" prop="company">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          style="width: 150px"
          v-model="queryParams.mobile"
          placeholder="联系方式"
          clearable
        />
      </el-form-item>
      <el-form-item label="套餐" prop="product_id">
        <el-select
          v-model="queryParams.product_id"
          clearable
          placeholder="请选择"
          style="width: 150px"
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="550"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="user.name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.company"
        show-overflow-tooltip
        label="公司"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="efficacy_at"
        label="套餐生效日"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="expired_at"
        label="套餐到期日"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="套餐"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="upgrade.created_at"
        label="升级申请时间"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="upgrade.after_title"
        label="升级申请"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="opt" label="运营操作" align="center" width="170">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot-scope="{ row }">
          <div class="state-rows" v-if="`${row.state}` === '7'">
            <div
              :class="['state-btn', 'state-primary']"
              @click="handleAudit('refuse', row)"
            >
              关闭
            </div>
            <div
              :class="['state-btn', 'state-warning']"
              @click="handleAudit('agree', row)"
            >
              同意
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="user.operation_remarks"
        label="备注"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <span style="cursor: pointer" @click="handleOpts('remarks', row)">{{
            row.user.operation_remarks || "-"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <RemarksView
      v-if="remarks.show"
      :show.sync="remarks.show"
      v-bind="remarks"
      @update="getList(1)"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import RemarksView from "../components/remarks.vue";

const URL = {
    list: "api/operation/order/productList",
    productList: "api/common/productList",
    upgradeAudit: "api/operation/order/upgradeAudit",
  },
  QUERY = {
    createTime: [],
    company: "",
    mobile: "",
    product_id: null,
  };
export default {
  components: {
    RemarksView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      productList: [],
      tableData: [],
      transform: {
        show: false,
        data: null,
      },
      remarks: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
    this.getProductList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getProductList() {
      const res = await HTTP({
        url: URL.productList,
        method: "get",
      });
      this.productList = res || [];
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const [beginTime = null, endTime = null] =
        this.queryParams.createTime || [];

      const params = {
        beginTime,
        endTime,
        company: this.queryParams.company,
        mobile: this.queryParams.mobile,
        product_id: this.queryParams.product_id,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleAudit(state, { orderno }) {
      this.$confirm("是否执行此操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.upgradeAudit,
            method: "post",
            data: {
              orderno,
              state,
            },
          }).then(() => {
            this.$message.success("操作成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消操作");
        });
    },
    handleOpts(type, row) {
      switch (type) {
        case "remarks":
          this.remarks = {
            show: true,
            data: {
              userId: row.user.id,
              content: row.user.operation_remarks,
            },
          };
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.state-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
}
</style>
