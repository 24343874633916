<!--
 * @Description: 开票
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-28 10:59:21
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/mods/invoicing.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="时间:" prop="createTime">
        <el-date-picker
          align="center"
          style="width: 240px"
          v-model="queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司名称" prop="search">
        <el-input
          style="width: 150px"
          v-model="queryParams.search"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          style="width: 150px"
          v-model="queryParams.mobile"
          placeholder="联系方式"
          clearable
        />
      </el-form-item>
      <!-- <el-form-item label="套餐" prop="state">
        <el-select
          v-model="queryParams.state"
          clearable
          placeholder="请选择"
          style="width: 100px"
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="550"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="公司"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="申请日期"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="header"
        label="发票抬头"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="taxnumber"
        label="企业税号"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="mailbox"
        label="邮箱地址"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
    list: "api/operation/order/invoiceList",
    productList: "api/common/productList",
  },
  QUERY = {
    createTime: [],
    mobile: "",
    search: "",
    state: null,
  };
export default {
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      productList: [],
      tableData: [],
      transform: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
    // this.getProductList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getProductList() {
      const res = await HTTP({
        url: URL.productList,
        method: "get",
      });
      this.productList = res || [];
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const [beginTime = null, endTime = null] =
        this.queryParams.createTime || [];

      const params = {
        beginTime,
        endTime,
        search: this.queryParams.search,
        mobile: this.queryParams.mobile,
        state: this.queryParams.state,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.state-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
}
</style>
