<!--
 * @Description: 合同查看
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:21:44
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/mods/contract.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="时间:" prop="createTime">
        <el-date-picker
          align="center"
          style="width: 240px"
          v-model="queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司名称" prop="company">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input
          style="width: 150px"
          v-model="queryParams.mobile"
          placeholder="联系方式"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-select
          style="width: 150px"
          v-model="queryParams.state"
          placeholder="请选择"
        >
          <el-option label="未签约" :value="1"></el-option>
          <el-option label="已签约待付款" :value="2"></el-option>
          <el-option label="已付款并上传凭证" :value="3"></el-option>
          <el-option label="已确认付款" :value="4"></el-option>
          <el-option label="服务生效" :value="6"></el-option>
          <el-option label="升级中" :value="7"></el-option>
          <el-option label="退款中" :value="8"></el-option>
          <el-option label="已退款" :value="9"></el-option>
          <el-option label="已过期" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="550"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="user.name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.company"
        show-overflow-tooltip
        label="公司"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="contract_sign_at"
        label="合同签署日"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="zx"
        label="状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="`${row.state}` === '8'" style="color: #ff7800">{{
            row.state_text
          }}</span>
          <span>{{ row.state_text }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="lastRefund.created_at"
        label="退款申请日期"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="opt"
        label="退款申请操作"
        align="center"
        width="170"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot-scope="{ row }">
          <div class="state-rows" v-if="`${row.state}` === '8'">
            <div
              :class="['state-btn', 'state-primary']"
              @click="handleOpts('refuse', row)"
            >
              拒绝
            </div>
            <div
              :class="['state-btn', 'state-warning']"
              @click="handleOpts('agree', row)"
            >
              同意
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="wb" label="合同文本" align="center">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            content="查看文件"
            placement="top"
            v-if="row.contract_file_path"
          >
            <span
              style="color: #4e6bef; cursor: pointer"
              @click="handleFile(row)"
            >
              <i class="el-icon-view"></i>
              查看
            </span>
          </el-tooltip>
          <el-button
            v-else
            size="mini"
            type="text"
            icon="el-icon-plus"
            @click="handleOpts('contract', row)"
            >上传</el-button
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="user.operation_remarks"
        label="备注"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <span style="cursor: pointer" @click="handleOpts('remarks', row)">{{
            row.user.operation_remarks || "-"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <!-- 上传合同 -->
    <ContractView
      v-if="contract.show"
      :show.sync="contract.show"
      v-bind="contract"
      @update="getList()"
    />

    <RemarksView
      v-if="remarks.show"
      :show.sync="remarks.show"
      v-bind="remarks"
      @update="getList(1)"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import ContractView from "../components/contract.vue";
import RemarksView from "../components/remarks.vue";

const URL = {
    list: "api/operation/order/contractList",
    refundAudit: "api/operation/order/refundAudit",
  },
  QUERY = {
    createTime: [],
    company: "",
    mobile: "",
    state: null,
  };
export default {
  components: {
    ContractView,
    RemarksView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      contract: {
        show: false,
        data: null,
      },
      remarks: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const [beginTime = null, endTime = null] =
        this.queryParams.createTime || [];

      const params = {
        beginTime,
        endTime,
        company: this.queryParams.company,
        mobile: this.queryParams.mobile,
        state: this.queryParams.state,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleAudit(state, { orderno }) {
      this.$confirm("是否执行此操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.refundAudit,
            method: "post",
            data: {
              orderno,
              state,
            },
          }).then(() => {
            this.$message.success("操作成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消操作");
        });
    },
    // 查看文件
    handleFile({ contract_file_path }) {
      window.open(contract_file_path);
    },
    handleOpts(type, row = null) {
      switch (type) {
        case "refuse":
        case "agree":
          this.handleAudit(type, row);
          break;
        case "contract":
          this.contract = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "remarks":
          this.remarks = {
            show: true,
            data: {
              userId: row.user.id,
              content: row.user.operation_remarks,
            },
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.state-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
}
</style>
