<!--
 * @Description: 客户管理
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:08:20
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/index.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">客户管理</div>
    <div class="head-tabs">
      <div
        :class="['tab-item', { active: activeIndex === '1' }]"
        @click="activeIndex = '1'"
      >
        客户总览
      </div>
      <div
        :class="['tab-item', { active: activeIndex === '2' }]"
        @click="activeIndex = '2'"
      >
        合同查看
      </div>
      <div
        :class="['tab-item', { active: activeIndex === '3' }]"
        @click="activeIndex = '3'"
      >
        套餐详情
      </div>
      <div
        :class="['tab-item', { active: activeIndex === '4' }]"
        @click="activeIndex = '4'"
      >
        财务管理
      </div>
      <div
        :class="['tab-item', { active: activeIndex === '5' }]"
        @click="activeIndex = '5'"
      >
        开票信息
      </div>
    </div>
    <!-- 客户总览 -->
    <OverView v-if="activeIndex === '1'" />
    <!-- 合同查看 -->
    <ContractView v-else-if="activeIndex === '2'" />
    <!-- 套餐详情 -->
    <PackageView v-else-if="activeIndex === '3'" />
    <!-- 财务管理 -->
    <FinanceView v-else-if="activeIndex === '4'" />
    <!-- 开票信息 -->
    <InvoicingView v-else-if="activeIndex === '5'" />
  </div>
</template>

<script>
import OverView from "./mods/overview.vue";
import ContractView from "./mods/contract.vue";
import PackageView from "./mods/package.vue";
import FinanceView from "./mods/finance.vue";
import InvoicingView from "./mods/invoicing.vue";
export default {
  components: {
    OverView,
    ContractView,
    PackageView,
    FinanceView,
    InvoicingView,
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
};
</script>

<style lang="scss" scoped>
.head-tabs {
  display: flex;
  align-items: center;
  margin-top: 28px;
  .tab-item {
    width: 84px;
    height: 33px;
    background: #f6f9ff;
    border: 1px solid #bec3cb;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
    font-weight: normal;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12px;
    position: relative;
  }
  .active {
    border: 1px solid #4586f0;
    color: #4586f0;
  }
}
</style>
