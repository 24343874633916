<!--
 * @Description: 客户总览
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:15:16
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/mods/overview.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="时间:" prop="createTime">
        <el-date-picker
          align="center"
          style="width: 240px"
          v-model="queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司名称" prop="company">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="mini"
          @click="handleOpts('add')"
          >录入客户</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="550"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="created_at"
        show-overflow-tooltip
        label="时间"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="business_mobile"
        show-overflow-tooltip
        label="留资电话"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="公司(简称)"
        align="center"
      >
        <template slot-scope="{ row }">
          {{ row.company }}({{ row.company_shortname }})
        </template>
      </el-table-column>
      <el-table-column
        prop="company_industry"
        show-overflow-tooltip
        label="行业"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="brand"
        show-overflow-tooltip
        label="品牌"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="lastOrder.title"
        label="套餐"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="lastOrder.contract_sign_at"
        label="合同签署日"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="operation_remarks"
        label="备注"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <span style="cursor: pointer" @click="handleOpts('remarks', row)">{{
            row.operation_remarks || "-"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <RemarksView
      v-if="remarks.show"
      :show.sync="remarks.show"
      v-bind="remarks"
      @update="getList(1)"
    />

    <!-- 录入客户 -->
    <CustomView
      v-if="custom.show"
      :show.sync="custom.show"
      @update="getList(1)"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import RemarksView from "../components/remarks.vue";
import CustomView from "../components/custom.vue";

const URL = {
    list: "api/operation/order/customerOverview",
  },
  QUERY = {
    createTime: [],
    company: "",
  };
export default {
  components: {
    RemarksView,
    CustomView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      remarks: {
        show: false,
        data: null,
      },
      custom: {
        show: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const [beginTime = null, endTime = null] =
        this.queryParams.createTime || [];

      const params = {
        beginTime,
        endTime,
        company: this.queryParams.company,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleOpts(type, row) {
      switch (type) {
        case "remarks":
          this.remarks = {
            show: true,
            data: {
              userId: row.id,
              content: row.operation_remarks,
            },
          };
          break;
        case "add":
          this.custom = {
            show: true,
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.state-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
}
</style>
