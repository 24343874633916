<!--
 * @Description: 财务管理
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:44:05
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/mods/finance.vue
-->
<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="付款状态" prop="state">
        <el-select
          v-model="queryParams.state"
          clearable
          placeholder="请选择"
          style="width: 150px"
        >
          <el-option label="待付款" value="unpay"></el-option>
          <el-option label="已付款" value="payed"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称" prop="company">
        <el-input
          style="width: 150px"
          v-model="queryParams.company"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="550"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="user.name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="user.company"
        show-overflow-tooltip
        label="公司"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="contract_sign_at"
        label="合同签署日"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="套餐"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="pay_state_text"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="payed_at"
        label="付款日期"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="opt" label="操作" align="center" width="170">
        <template slot-scope="{ row }">
          <div class="state-rows">
            <div
              :class="['state-btn', 'state-primary']"
              @click="handleOpts('voucher', row)"
            >
              查看凭证
            </div>
            <div
              :class="[
                'state-btn',
                { 'state-warning': row.pay_state === 'unpay' },
              ]"
              @click="
                () =>
                  row.pay_state === 'unpay' ? handleOpts('payment', row) : null
              "
            >
              确认付款
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <!-- 凭证 -->
    <VoucherView
      v-if="voucher.show"
      :show.sync="voucher.show"
      v-bind="voucher"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import VoucherView from "../components/voucher.vue";

const URL = {
    list: "api/operation/order/financeList",
    confirmPayed: "api/operation/order/confirmPayed",
  },
  QUERY = {
    company: "",
    state: null,
  };
export default {
  components: {
    VoucherView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      voucher: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const params = {
        company: this.queryParams.company,
        state: this.queryParams.state,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },

    // 确认付款
    handlePayment(orderno) {
      this.$confirm("是否执行此操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.confirmPayed,
            method: "post",
            data: {
              orderno,
            },
          }).then(() => {
            this.$message.success("操作成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消操作");
        });
    },

    handleOpts(type, row = null) {
      switch (type) {
        case "voucher":
          this.voucher = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "payment":
          this.handlePayment(row.orderno);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.state-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    cursor: pointer;
  }
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
}
</style>
