<!--
 * @Description: 凭证
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-28 10:40:59
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/manage/components/voucher.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">凭证</div>
      <div class="model-content">
        <div class="content-img">
          <img :src="data.payed_img_path" alt="" />
        </div>
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 751px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      width: 647px;
      overflow: hidden;
      margin: auto {
        top: 40px;
        bottom: 50px;
      }
      .content-img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 18px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
